.foot {
  background-color:#684571;
padding-bottom: 2%;
}

.footimg {

}
.foothead {
  display: block;
text-align: center;
margin-left: auto;
margin-right: auto;
font-size: clamp(20px, 3vw, 50px);
color: white;
padding-top: 2%;
padding-bottom: 2%;

}
.foottext {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 40%;
  min-width: 350px;
  color: white;
  align-items: flex-end;
  font-family: 'Josefin Sans', sans-serif;
  line-height: clamp(10px, 3vw, 30px);
  padding-bottom: 5%;

}
.footcontact {
    font-size: clamp(20px, 3vw, 40px);
    line-height: 200%;
}

.footp {
    font-size: clamp(10px, 3vw, 20px);
}
.hand{
  display: block;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    border-top-right-radius: 50px;
    border-width: thin;
    border-style: solid;
    border-color: #C73D0B;
}

.handle {
  font-size: clamp(10px, 3vw, 20px);
}

.linktext{
  color:inherit;
  font-size: clamp(20px, 3vw, 40px);
}
