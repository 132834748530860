.logo {
  color: white;
}

.navbar-text {
  color: white;
}

.navbar-mid {
  background-color: #FE6027;
}
.navbarmain{

}

.navlink{
  text-decoration: none;
}
.nav{
  padding-top: 0 !important;
}
.navbarhead{
  padding-top: 0 !important;
padding-bottom: 0 !important;

}