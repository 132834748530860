.me {
  background-color: black;
padding-bottom: 10%;
padding-top: 10%;
}

.behind{
font-size: clamp(5px, 3vw, 15px);
text-align: center;
color: white;
font-family: 'Josefin Sans', sans-serif;
}

.keerti {
  width: 60%;
  height: auto;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
border-top-right-radius: 20px;;
border-bottom-left-radius: 2rem;
  border-width: 2%;
  border-image: linear-gradient(to right, #C73D0B, white , black) 1;
  border-style: solid;
  background-color: black;
}
 .me-text{
 
   color: white;
   padding-top: 5%;
   text-align: justify;
   margin-left: auto;
   margin-right: auto;
   font-family: 'Josefin Sans', sans-serif;
 }

 .cheers{
    font-size: clamp(15px, 2vw, 30px);
    color: linear-gradient(to right, black, #C73D0B, #684571);
    font-family: 'Josefin Sans', sans-serif;
    text-align: justify;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
 }

 .cheers2{
   font-size: clamp(15px, 3vw, 30px);
   color: black;
   text-align: center;
   font-family: 'Josefin Sans', sans-serif;
 }
 .about{
   color: #C73D0B;
   text-align: center;
   font-family: 'Josefin Sans', sans-serif;
 }

 .why {
  background-color: black;
  padding: 20px;

 }

 .why-para {
color:darkgrey;
 }

 .why-heads{

  text-align: center;
  color:#C73D0B;
 }

 .card-head{
  font-size: clamp(20px, 2vw, 30px);
  text-align: center;
  color:#C73D0B;
 }

 .why-card-img {
  height: 200px;
 }

 .card-text1{
  height: 8rem;
  overflow-y: scroll;
  scroll-behavior: auto;
  font-size: clamp(12px, 1.1vw, 16px);
  color:#684571;
 }

 .expert-head {
  color:#C73D0B;
  font-size: clamp(20px, 2vw, 30px);
text-align: center;
  padding: 3%;

 }
 .expert-head-2 {
  color:#C73D0B;
  font-size: clamp(30px, 3.5vw, 40px);
text-align: center;
  padding: 3%;
margin-top: 5%;
vertical-align: middle;
 }
 .expertise-points {
  margin-top: 10%;
 }
 .expert-para{
  color:dimgrey;
  text-align: justify;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10%;
  padding-right: 10%;
 }

 .expertise {
  padding-top: 5%;
 }

 .about1 {
  background-color: #684571;
  margin: 0 !important;

  padding-top: 5%;
  padding-bottom: 2%;

 }



 .butt-me{
  background-color: #684571;
  color: white;
  width: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 2%;
margin-top: 5%;
text-decoration: none !important;
 }

 .butt-h1{
  font-size: clamp(15px, 1.5vw, 30px);
  text-decoration: none !important;

 }
.img-expert {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
}

.line{
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 70%;
  border-style: solid;
  border-color:aliceblue;
  border-width: 2px;
  margin-top: 5%;
  margin-bottom: 5%;
  
}


.why-card{
  background-color:gainsboro;
}