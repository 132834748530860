.contact-head{
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #478FD7;
  text-align: center;
  padding:2%;
}

.addcontact{
  padding-left: 5%;
  padding-right: 5%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.addhead{
  text-align: center;
  color: #155BA1;
  margin-top: 2%;
}

.addform{
  text-align: center;
}

.addtext{
  padding: clamp(5px, 2vw, 15px);
  font-size: clamp(12px, 2vw, 20px);
}

.addbutt{
  background-color: #478FD7;
  margin-top: 2%;
  color:white;
  padding-top: clamp(5px, 2vw, 10px);
  padding-right: clamp(15px, 2vw, 30px);
  padding-bottom: clamp(5px, 2vw, 10px);
  padding-left: clamp(15px, 2vw, 30px);
}

.card{
  display:block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
}
.cardimg{
  width: 5%;
}

.contactbutt{
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  background-color: black;
  color: white;
  border-radius: clamp(5px, 2vw, 10px);


}

.contacts{
  min-height: 100vh;
}
