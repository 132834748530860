.drum-body {
  background-color: #283149;
}

.drum-h1 {
  padding-top: 4%;
  text-align: center;
  font-size: clamp(40px, 4vw, 50px);
  color: #DBEDF3;
  font-family: "Arvo", cursive;
  text-shadow: 3px 0 #DA0463;

}

.drum-footer {
  color: #DBEDF3;
  font-family: sans-serif;
  display: block;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
}

.w {
background-image: url("./images/crash.png");

}

.a {
background-image: url("./images/kick.png");
}

.s {
background-image: url("./images/snare.png");
}

.d {
background-image: url("./images/tom1.png");
}

.j {
background-image: url("./images/tom2.png");
}

.k {
background-image: url("./images/tom3.png");
}

.l {
background-image: url("./images/tom4.png");
}

.set {
  margin: 10% auto;
}

.game-over {
  background-color: red;
  opacity: 0.8;
}

.pressed {
  box-shadow: 0 3px 4px 0 #DBEDF3;
  opacity: 0.5;
}

.red {
  color: red;
}

.drum {
  outline: none;
  border: 10px solid #404B69;
  font-size: 5rem;
  font-family: 'Arvo', cursive;
  line-height: 2;
  font-weight: 900;
  color: #DA0463;
  text-shadow: 3px 0 #DBEDF3;
  border-radius: 15px;
width: 150px;
height: 150px;
  background-color: white;
}

.drumcol{
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.drumbutt{
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
