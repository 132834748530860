.kiki {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 50px;
}

.kiki {
  position: relative;
  font-family: sans-serif;
  text-transform: uppercase;
    font-size: clamp(10px, 4vw, 30px);
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, #000, #FE6027, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}
