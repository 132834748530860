.wall {
background-color: black;
}

.wall-img{
  width: 100%;
}

.web{
  color: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10%;
  padding-right: 10%;
  font-size: clamp(30px, 4vw, 60px);
}

.main{
  color: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
 margin-bottom: 10%;
  font-size: clamp(15px, 2vw, 40px);
  color: #684571;
}

.contact{
  display: block;
  left: auto;
  right: auto;
  text-align: center;

}

.butt{
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 1%;
  padding-bottom: 1%;
font-size: clamp(18px, 2vw, 30px);
margin-top: 2%;
margin-bottom: 10%;
background-color: black;
color: #FE6027;
border-width: thin;
border-color: #FE6027;

}
/* Animation property */
.butt {
  animation: wiggle 2s linear infinite;
}

/* Keyframes */
@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}
