.quiz {
  text-align: center;
  background-color: #7cc6fe;
 width: 100%;
  padding-top: 10%;
  padding-bottom: 10%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Verdana", cursive, sans-serif;
  color: #ffffff;
}


.score-section {
  display: block;
  font-size: 2rem;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
  width: 50%;
  min-width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.question-count {
  margin-bottom: 20px;
}

.question-count {
  font-size: 28px;
}

.question-text {
  margin-bottom: 12px;
  font-size: clamp(20px, 3vw, 40px);
}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
  width: 50%;
  min-width: 300px;
  display: block;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.quiz-button {
  width: 80%;
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 4%;;
  margin: 2%;
  justify-content: flex-start;
  align-items: center;
  border: thin solid black;
  cursor: pointer;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

.quiz-button:hover {
  background-color: #555e7d;
}

.quiz-button:focus {
  outline: none;
}

.quiz-button {
  margin-right: 5px;
}

.quizbutt{
  display: block;
  margin-bottom: 10%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: black;
  color: white;
  border-radius: clamp(10px, 3vw, 20px);
  padding-top: clamp(10px, 3vw, 20px);
  padding-right: clamp(10px, 3vw, 40px);
  padding-left: clamp(10px, 3vw, 40px);
  padding-bottom: clamp(10px, 3vw, 20px);
}
