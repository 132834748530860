.price{
  padding-top: 5%;
background-image: linear-gradient(to right, black, #C73D0B, #684571);
padding-bottom: 10%;}

.pricing{
  color: white;
  text-align: center;
  padding-bottom: 5%;
  font-size: 3rem;
font-size: clamp(30px, 3vw, 50px);
}

.tag{
  position: relative;
  height: 60%;
  padding-bottom:5%;
}
.hide1{
  display: none;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: white;
}
.hide2{
  display: none;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: white;
}
.hide3{
  display: none;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: white;

}
.hidehead{
  font-size: clamp(20px, 3vw, 40px);
  line-height: clamp(40px, 4vw, 60px);
  font-family: 'Josefin Sans', sans-serif;
}
.hidetext{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-size: clamp(10px, 3vw, 20px);
  line-height: 5px;
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;

}
.pricetext1:hover + .hide1 {
  display: block;
}
.pricetext1:hover{
  opacity: 50%;
}
.pricetext1{
  border-width: medium;
  border-style: solid;
  border-color: white;
  position: sticky;
  background-image: url("./images/basic-1.jpeg");

  min-height: 150px;
  width: 50%;
  min-width: 200px;
  background-size: cover;
  color: white;
  font-size: clamp(30px, 3vw, 50px);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  line-height: 100px;
  margin-top: 10%;
}

.pricetext2:hover + .hide2 {
  display: block;
}
.pricetext2:hover{
  opacity: 50%;
}
.pricetext2{
  border-width: medium;
  border-style: solid;
  border-color: white;
  margin-left: auto;
  margin-right: auto;
  background-image: url("./images/basic-2.jpeg");

  min-height: 150px;
  width: 50%;
  min-width: 200px;
  background-size: cover;
    color: white;
    font-size: clamp(30px, 3vw, 50px);
    text-align: center;
    line-height: 100px;
    margin-top: 10%;
}

.pricetext3:hover + .hide3 {
  display: block;
}
.pricetext3:hover{
  opacity: 50%;
}
.pricetext3{
  border-width: medium;
  border-style: solid;
  border-color: white;
  margin-left: auto;
  margin-right: auto;
  background-image: url("./images/basic-3.jpeg");

  min-height: 150px;
  width: 50%;
  min-width: 200px;
  background-size: cover;
    color: white;
    font-size: clamp(30px, 3vw, 50px);
    text-align: center;
    line-height: 100px;
    margin-top: 10%;
}
