.slider {
  padding-top: 5%;
  padding-bottom: 8%;
  background-color: black;

}

.designhead{
  text-align: center;
  color: white;
  margin-bottom: 5%;
    font-size: clamp(20px, 3vw, 50px);
}

.designpic {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.carousal{
  color: #C73D0B;

}
