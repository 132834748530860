.dice-container {
  margin: auto;
  text-align: center;
  background-color: #393E46;
  height: 100%;

}

.dice {
  text-align: center;
  display: inline-block;

}


.dice-head {
  padding-top: 10%;
  font-family: 'Lobster', cursive;
  text-shadow: 5px 0 #232931;
  font-size: clamp(40px, 4vw, 50px);
  color: #4ECCA3;
}

.dice-p {
  font-size: clamp(20px, 3vw, 30px);
  color: #4ECCA3;
  font-family: 'Indie Flower', cursive;
  padding-top: 5%;
}

.img2 {
  width: 40%;
}

.dice-foot{
  padding-top: 5%;
  color: #EEEEEE;
  text-align: center;
  font-family: 'Indie Flower', cursive;
  padding-bottom: 10%;
}

.startbutt{
  margin: 4%;
}

.dicebutt{
  margin-top: 5%;
}
