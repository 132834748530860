.profile{
color: black;
background-color: #BFFAF2;
font-size: 2rem;
}
.thumbnail {
  width: 70%;
  padding: 4%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: clamp(10px, 3vw, 30px);
}

.thumbnail:hover {
  transform: scale(1.2);
  transition: all 0.2s  0.1s;
}

.homebutt{
width: 150px;
padding: 1%;
}
.homeback {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 2%;

}
.end {
  display: block;
  text-align: center;
  font-size: 100%;
  background-color:black;
  color:white;
  padding: 2%;
  
}
.contact {
  font-size: 1.5rem;
}

.apps {
  margin-left: auto;
  margin-right: auto
}

.top-cloud {
  position: relative;
  left: 60%;
width: 200px; height: auto;
}
.name {
  text-align: center;
  font-family: cursive;
  font-size: 150%;
  left: 100px;
}
.cloud {
display: block;
position: relative;
width: 200px; height: auto;
animation-direction: alternate;
animation-play-state: running;

}
.mountain {
  width: 10px; height: auto;
}

.home-hr{
  border-color: white;
  border-style: dashed;
  border-top-width: thick;
  border-bottom-width: 0;
width: 80%;
}
.photo{
  width: 300px; height: auto;
margin-left: auto;
margin-right: auto;
}
.content{
display: inline;
font-family: 'Freehand', cursive;
text-align: center;
font-size: 200%;

}


.pro:hover {
  transform: scale(2);
  transition: all 0.2s  0.1s;
}
