.weather {
    margin-left: 10%;
    margin-right: 10%;
    border-style: dotted;
    margin-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
   margin-bottom: 5%;
    padding-bottom: 2%;
    border-radius: 20px;
    border-width: thick;
    border-color: grey;
  }
  
  .heading-weather {
    text-align: center;
    font-family: 'Noto Serif', serif;
    font-size: 50px;
  }
  
  .city-weather{
    border-style: none;
    border-bottom-style: dotted;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:80%;
    background-color: #dee9fa;
    padding: 2%;
    border-bottom-width: medium;
  }
  
  .country-weather {
    border-style: none;
    border-bottom-style: dotted;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:80%;
    background-color: #dee9fa;
    padding: 2%;
    border-bottom-width: medium;
  }
  
  .container-weather {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .submit-weather{
    width: 80%;
    padding: 5%;
    background-color: #4a638a;
    color: white;
    border-radius: 20px;
    font-weight: 600;
    font-size: 20px;
  }
  
  .box-weather{
    border-style: solid;
    border-color: grey;
    border-width: medium;
    padding: 2%;
    background-color: #e8eaeb;
    border-radius: 20px;
    color:#535454;
  }
  
  .temp-weather{
    font-size: 60px;
    color: black;
    text-align: center;
  }
  
  .smoke-weather{
    background-image: url("../images/smoke.png");
  }
  
  .rowss-weather{
    margin-bottom: 5%;
  }
  
  .span1-weather{
    border-bottom-style: dashed;
    border-bottom-width: medium;
    border-color: grey;
    padding-bottom: 3%;
  
  }
  
  .span2-weather{
    margin-left: 10%;
    border-bottom-style: dashed;
    border-bottom-width: medium;
    border-color: grey;
    padding-bottom: 3%;
  }
  
  .bold-weather{
    font-size: 25px;
    font-weight: bold;
  }
  
  .not-bold-weather{
    font-size: 20px;
    color: grey;
    font-weight: 500;
  }
  
  .extra-weather{
    padding-top: 5%;
  }
  