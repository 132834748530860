.samples {
  background-color: #DDBBE9;
  height: 100%;;
  padding-top: 5%;
  padding-bottom: 100%;
}

.samplehead {
  color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-bottom: 3%;
  font-family: 'Roboto Mono', monospace;
  text-decoration-style: solid;
  text-transform: full-width;
  font-size: clamp(20px, 3vw, 50px);
  margin-top: 2%;
}
.coorg{
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.coorgbutt{
  width: 15%;
  min-width: 200px;
  border-width: clamp(1px, 1vw, 5px);
  border-color: #75009F;
  border-style: solid;
  border-radius: clamp(10px, 3vw, 30px);
margin-bottom: 2%;
}
.coorgtext {
  color: #75009F;
  font-size: clamp(15px, 3vw, 30px);
}



.more {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 50px;
  font-family: 'Roboto Mono', monospace;
}

.more {
  position: relative;
  text-transform: uppercase;
    font-size: clamp(10px, 4vw, 30px);
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, white, #75009F, white);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}

.coorgback{
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.coorgdetail{
  font-size: clamp(8px, 3vw, 20px);

}

.client-img{
  width: 100px;
  height: 100px;

}

.open{
  background-color:#684571;
  color: white;
  padding: 5%;
  border-style: none;
}
.cards {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.client-card-img{
  height:200px;
 
}