.recipe {
  background: cyan;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.recipe-box {
  display: block;
  border-style: solid;
  border-radius: 10%;
  background-color: white;;
  font-size: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  min-width: 400px;
  margin-top: 5%;
  margin-bottom: 5%;

}

.recipeimg{
  width: 20%;
}
.search {
  color: black;
  height: 50px;
  weight: 400%;
  font-size: 2rem;
  margin: 4%;
}

.btn {
  margin-left: 5%;
}
