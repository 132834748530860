:root {
  --primary: #d81e5b;
  --secondary: #8c38ff;
  --dark: #131a26;
  --light: #eee;
}

.calcTotal {
  box-sizing: border-box;
  font-family: 'Fira Sans', sans-serif;
  background-color: white;
    display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

}

.calc {
  display: block;
  padding-top: 10%;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  display: block;
margin-left: auto;
margin-right: auto;
text-align: center;
}

.calculator {
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  background-color: #FFF;
  border-radius: 16PX;
  overflow: hidden;
  box-shadow: 0px 2px 64px rgba(0, 0, 0, 0.2);
  display: block;
margin-left: auto;
margin-right: auto;
text-align: center;
}

.display {
  padding: 16px;
  text-align: right;
  background-color: var(--dark);
  color: var(--light);
  font-size: 24px;
}

.display span{
  font-size: 14px;
  color: #888;
}

.calc-button{
  appearance: none;
  border: none;
  outline: none;
   color: #FFF;
   font-size: 20px;
   padding: 16px;
   cursor: pointer;
   transition: 0.4s;
}
.calcbutt {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-bottom: 5%;
}

.calc-button:hover {
  opacity: 0.9;
}

.operators {
  display: flex;
}

.operators .calc-button {
  flex: 1 1 0%;
  background-color: var(--primary);
  font-weight: 700;
}
.digits{
  display: flex;
  flex-wrap: wrap;
}

.digits .calc-button{
  flex: 1 1 33.333%;
  max-width: 33.333%;
  background-color: var(--dark);
}
.button{
  margin-top: 5%;
  display: block;
}
